import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import HeaderMobile from "./HeaderMobile";

interface LayoutProps {
  children: React.ReactNode;
}

const FloatingTag = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(38, 38, 38, 0.8);
  backdrop-filter: blur(5px);
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: var(--font-family-Font-1, Inter);
  
  span {
    color: #C084FC;
    font-weight: 600;
  }
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <LayoutWrapper>
      {/* <Header /> */}
      {isMobile ? <HeaderMobile /> : <Header />}
      <Main>{children}</Main>
      <Footer />
      <FloatingTag>
        Built with <span>BragAI</span> in 3 minutes
      </FloatingTag>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  // background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Layout;
