import React from "react";
import styled from "styled-components";

// import logo from "@/assets/img/brag-w.png"
import logo from "@/assets/img/bragai(new).png"

interface LogoProps {
  // Add any props if needed
}

const handleNavigate = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

const Logo: React.FC<LogoProps> = () => {
  return (
    <LogoWrapper onClick={handleNavigate}>
      <LogoImage
        loading="lazy"
        // src="https://cdn.builder.io/api/v1/image/assets/TEMP/79fddc5efb570c3583e2b2cfa592c36c71d1e215fc45aa0272b110ccf2e02257?placeholderIfAbsent=true&apiKey=664b92c979f845bcb3e2ba656caaab88"
        src={logo}
        alt="BragAI Logo"
      />
      <LogoText>
        <span className="bold">Brag</span>
        <span className="regular">AI</span>
      </LogoText>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: var(--brag-ai-white, var(--color-white-solid, #fff));
  font-weight: 600;
  line-height: var(--line-height-24, 24px);
  justify-content: start;
  padding: 4px 0;
  cursor: pointer;
`;

const LogoImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  max-width: 81px;
  margin: auto 0;
`;

const LogoText = styled.div`
  align-self: stretch;
  margin: auto 0;

  .bold {
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
  }

  .regular {
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
`;

export default Logo;
