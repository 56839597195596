import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FeatureCard from "./FeatureCard";
import FeatureCardMobile from "./FeatureCardMobile";

import a from "@/assets/img/home/_a.jpeg";
import b from "@/assets/img/home/_b.jpeg";
import c from "@/assets/img/home/_c.jpeg";
import d from "@/assets/img/home/_d.jpeg";

interface FeatureData {
  title: string;
  description: string;
  imageSrc: string;
}

const features: FeatureData[] = [
  {
    title: "Build Full-Stack Apps Instantly",
    description:
      "BragAI turns your ideas into fully functional web applications with just a simple prompt, no coding knowledge required.",
    imageSrc: a,
  },
  {
    title: "AI That Thinks Like a Senior Engineer",
    description:
      "BragAI reasons through your code step by step, explains decisions, and lets you edit code in real time, install packages, and run commands with a live CLI terminal, just like an expert developer would.",
    imageSrc: b,
  },
  {
    title: "Train Your AI, Your Way",
    description:
      "Upload PDFs, images, and documentation, securely store environment variables, and fine-tune BragAI with custom instructions to match your needs.",
    imageSrc: c,
  },
  {
    title: "Debug Smarter, Not Harder",
    description:
      "Instantly analyze, debug, and optimize your code with AI-powered fixes—no more getting stuck on errors.",
    imageSrc: d,
  },
];

const FeatureSection: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Section>
      {features.map((feature, index) => (
        isMobile ? (
          <FeatureCardMobile
            key={index}
            {...feature}
            isReverse={index % 2 === 0}
          />
        ) : (
          <FeatureCard
            key={index}
            {...feature}
          />
        )
      ))}
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 160px;
  margin-top: 100px;
  @media (max-width: 991px) {
    gap: 40px;
    margin-top: 40px;
  }
`;

export default FeatureSection;
