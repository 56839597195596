import React from "react";
import styled from "styled-components";
import WaitListForm from "./WaitListForm";

const CallToAction: React.FC = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Title>Join BragAI's waitlist.</Title>
        </Content>
          <WaitListForm placeholder="Enter your email" buttonText="Join Waitlist"/>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  align-self: stretch;
  display: flex;
  // margin-top: 96px;
  flex-direction: column;
  overflow: hidden;
  font-family: var(--font-family-Font-1, Inter);
  color: var(--brag-ai-white, var(--color-white-solid, #fff));
  justify-content: center;
  padding: 100px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 100px 0;
  }
`;

const Container = styled.div`
  border-radius: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 82px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  text-align: center;
  font-size: var(--font-size-48, 48px);
  font-weight: var(--font-weight-600, 600);
  line-height: var(--font-size-48, 48px);
  letter-spacing: var(--letter-spacing--0_72, -0.72px);
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

export default CallToAction;
