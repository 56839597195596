/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import styled, { keyframes, css } from "styled-components";
// import PreviewBanner from "./PreviewBanner";
import WaitListForm from "./WaitListForm";

interface HeroProps {
  // Add any props if needed
}

const Hero: React.FC<HeroProps> = () => {
  const subtitles = useMemo(() => [
    " .Think It. Build It. Brag It.",
    " Build full-stack apps in seconds.",
    " AI that thinks like a senior engineer.",
    " Import Figma designs, generate code.",
    " Train your AI, your way.",
    " Debug smarter, not harder.",
    " Powerful integrations for faster development.",
  ], []);

  const titleWords = useMemo(() => ["Imagine", "Build", "Brag"], []);
  const [currentWord, setCurrentWord] = useState(titleWords[0]);
  const [isTitleFading, setIsTitleFading] = useState(false);
  const [currentText, setCurrentText] = useState(""); // For displaying typed text
  const [currentTextIndex, setCurrentTextIndex] = useState(0); // For cycling through subtitles
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    let charIndex = 0;
    let timeoutId: NodeJS.Timeout;

    // Function to simulate typing effect
    const typeText = () => {
      if (charIndex < subtitles[currentTextIndex].length) {
        setCurrentText((prev: string) => prev + subtitles[currentTextIndex].charAt(charIndex));
        charIndex++;
        timeoutId = setTimeout(typeText, 100); // Adjust typing speed here (100ms per character)
      } else {
        // After typing is complete, wait for 2 seconds and then fade out
        setTimeout(() => {
          setIsFading(true);
        }, 2000); // Time before fading out
      }
    };

    // Add initial delay only for the first render
    const initialDelay = currentTextIndex === 0 ? 500 : 0;
    
    // Start typing with delay
    const startTyping = () => {
      setCurrentText(""); // Clear previous text
      setIsFading(false); // Reset fading state
      typeText();
    };

    timeoutId = setTimeout(startTyping, initialDelay);

    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, [currentTextIndex, subtitles]);

  useEffect(() => {
    if (isFading) {
      // Fade out and change the text after 1 second
      const fadeTimeout = setTimeout(() => {
        setIsFading(false);
        setCurrentTextIndex((prevIndex: number) => (prevIndex + 1) % subtitles.length); // Move to the next subtitle
      }, 1000); // Duration of the fade-out

      return () => clearTimeout(fadeTimeout);
    }
  }, [isFading, subtitles]);

  // Add new useEffect for title animation
  useEffect(() => {
    const titleAnimationInterval = setInterval(() => {
      setIsTitleFading(true);
      
      setTimeout(() => {
        setCurrentWord((prevWord) => {
          const currentIndex = titleWords.indexOf(prevWord);
          return titleWords[(currentIndex + 1) % titleWords.length];
        });
        setIsTitleFading(false);
      }, 1000); // Duration of fade out
      
    }, 2000); // Total duration of each word

    return () => clearInterval(titleAnimationInterval);
  }, [titleWords]);

  return (
    <HeroSection>
      <HeroTitleContainer>
        {/* <AnimatedWord isFading={isTitleFading}>{currentWord}</AnimatedWord> */}
        <StaticWords>Brag Your Idea</StaticWords>
      </HeroTitleContainer>
      <HeroSubtitle isFading={isFading}>{currentText}</HeroSubtitle>
      <HeroDescription>
        The fastest way to go from idea to production. With AI-driven reasoning, real-time coding, 
        one-click deployment, and powerful integrations, BragAI is your all-in-one senior engineer.
      </HeroDescription>
      {/* <ChatButton placeholder="Chat with Brag-preview-01"/> */}
      <WaitListForm placeholder="Enter your email" buttonText="Join Waitlist" />
      {/* <PreviewBanner label="Try Brag-preview-01, our first context-switching model" /> */}
      {/* <PreviewBanner label="Brag-preview-01 will be released on January 2025" /> */}
    </HeroSection>
  );
};

// Keyframes for animations
const fadeOut = keyframes`
  0% { 
    opacity: 1;
    transform: scale(1);
  }
  100% { 
    opacity: 0;
    transform: scale(0.8);
  }
`;

const fadeIn = keyframes`
  0% { 
    opacity: 0;
    transform: scale(1.2);
  }
  100% { 
    opacity: 1;
    transform: scale(1);
  }
`;

// Styled components
const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 225px;
`;

const HeroTitleContainer = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--brag-ai-porcelain, var(--color-grey-93, #ecedee));
  margin-top: 30px;
  font: var(--font-weight-400, 400) 90px / var(--line-height-96, 96px)
    var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    font-size: 40px;
    width: 80%;
    flex-wrap: wrap;
  }
`;

const AnimatedWord = styled.span<{ isFading: boolean }>`
  min-width: 280px;
  text-align: right;
  ${({ isFading }) =>
    isFading
      ? css`
          animation: ${fadeOut} 0.5s ease-in-out forwards;
        `
      : css`
          animation: ${fadeIn} 0.5s ease-in-out;
        `}
`;

const StaticWords = styled.span`
  margin-left: 20px;
  white-space: nowrap;
`;

const HeroSubtitle = styled.h2<{ isFading: boolean }>`
  color: var(--brag-ai-porcelain, var(--color-grey-93, #ecedee));
  margin-top: 0px;
  font: var(--font-weight-400, 400) var(--font-size-48, 48px) /
    var(--font-size-48, 48px) var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    font-size: 40px;
    width: 80%;
  }
  min-height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: visible;

  ${({ isFading }) =>
    isFading &&
    css`
      animation: ${fadeOut} 1s ease-in-out forwards;
    `}
`;

const HeroDescription = styled.p`
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  margin-top: 10px;
  font: 400 19px / var(--line-height-28, 28px) var(--font-family-Font-1, Inter);
  width: 60%;
  @media (max-width: 791px) {
    margin-top: 20px;
    width: 80%;
  }

  .bold {
    font-weight: 700;
  }
`;

export default Hero;
