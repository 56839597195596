import React from "react";
import styled from "styled-components";

interface FeatureCardProps {
  title: string;
  description: string;
  imageSrc: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  imageSrc,
}) => {
  return (
    <Card>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Image src={imageSrc} alt={title} loading="lazy" />
    </Card>
  );
};

const Card = styled.div`
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  width: 1120px;
  max-width: 100%;
  flex-direction: column;
  font-family: var(--font-family-Font-1, Inter);
  justify-content: start;
  padding: 25px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const Title = styled.h3`
  color: var(--brag-ai-white, var(--color-white-solid, #fff));
  font-size: var(--line-height-20, 20px);
  font-weight: var(--font-weight-700, 700);
  line-height: var(--line-height-28, 28px);
`;

const Description = styled.p`
  width: var(--width-484, 484px);
  align-self: start;
  margin-top: 8px;
  font-size: 14px;
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  font-weight: var(--font-weight-400, 400);
  line-height: var(--line-height-20, 20px);
`;

const Image = styled.img`
  aspect-ratio: 1.75;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 12px;
  margin-top: 8px;
  max-width: 1070px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default FeatureCard;
