import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { FaGithub } from 'react-icons/fa'; // Importing FaGithub icon from react-icons library

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Logo />
        <Copyright>Copyright © 2025 BragAI</Copyright>
        <AllRights>All rights reserved</AllRights>
        {/* <Support>support@brag.ai</Support> */}
        <Support><FaGithub style={{ marginRight: '5px', color: 'var(--brag-ai-silver-chalice, var(--color-grey-64, #a3a3a3))' }} /><a href="https://github.com/braGAI/" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--brag-ai-silver-chalice, var(--color-grey-64, #a3a3a3))' }}>GitHub</a></Support>
      </FooterContent>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  border-top: var(--stroke-weight-1, 1px) solid
    var(--brag-ai-mine-shaft, #262626);
  background: var(--color-black-solid, #000);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  color: var(--brag-ai-silver-chalice, var(--color-grey-64, #a3a3a3));
  padding: 81px 80px 131px;
  font: var(--font-weight-400, 400) 16px / var(--line-height-20, 20px) // Increased font size
    var(--font-family-Font-1, Inter); // Changed font family to Inter
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px 100px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  width: 200px;
  max-width: 100%;
  flex-direction: column;
  align-items: start;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Copyright = styled.p`
  align-self: stretch;
  margin-top: 21px;
`;

const AllRights = styled.p`
  margin-top: 14px;
`;

const Support = styled.p`
  margin-top: 15px;
`;

export default Footer;
