import React from "react";
import styled from "styled-components";

interface ThirdPartyIntegration {
  logo: string;
  title: string;
  description: string;
  isFeature?: boolean;
}

const ThirdPartyIntegrations: ThirdPartyIntegration[] = [
  {
    logo: "/integrations/figma-logo.svg",
    title: "Figma Integration",
    description: "Seamlessly integrate Brag with your Figma workflow. Extract design tokens, generate components, and maintain design consistency automatically.",
  },
  {
    logo: "/integrations/github-logo.svg",
    title: "GitHub Integration",
    description: "Connect your GitHub repositories to automate code reviews, generate documentation, and get AI-powered insights on your codebase.",
  },
  {
    logo: "/integrations/vercel-logo.svg",
    title: "One-Click Deployment",
    description: "Deploy your Brag-powered applications instantly to Vercel or Netlify with our streamlined deployment process.",
  },
  {
    logo: "/integrations/supabase-logo.svg",
    title: "Supabase Integration",
    description: "Leverage Supabase's powerful backend capabilities with Brag. Seamlessly manage data, authentication, and real-time features.",
  },
  {
    logo: "/integrations/version-logo.svg",
    title: "Version Control & Diffs",
    description: "View code changes, compare versions, and navigate through your code history with an intuitive diff viewer and version timeline.",
    isFeature: true
  },
  {
    logo: "/integrations/import-logo.svg",
    title: "Project Import",
    description: "Import existing projects from your local machine or GitHub repositories. Start working on your code instantly with all dependencies.",
    isFeature: true
  },
  {
    logo: "/integrations/blocks-logo.svg",
    title: "Visual Page Builder",
    description: "Build your UI with drag-and-drop blocks. Create complex layouts and components visually with our intuitive page builder interface.",
    isFeature: true
  },
  {
    logo: "/integrations/inspector-logo.svg",
    title: "Visual Inspector",
    description: "Make real-time edits directly on the preview. Inspect elements, modify styles, and see changes instantly without touching code.",
    isFeature: true
  },
  {
    logo: "/integrations/code-logo.svg",
    title: "Real-Time Code Editor",
    description: "Collaborative code editing with real-time sync. Work together with your team, see live changes, and get instant AI suggestions.",
    isFeature: true
  },
  {
    logo: "/integrations/terminal-logo.svg",
    title: "Live Terminal CLI",
    description: "Access powerful terminal commands directly in the browser. Run scripts, manage deployments, and debug with integrated console.",
    isFeature: true
  },
];

const IntegrationSection: React.FC = () => {
  return (
    <Section id="integrations">
      <FeaturesTitle>
        Powerful Integrations
        {/* Powerful Development Features */}
        {/* Third-Party Integrations */}
      </FeaturesTitle>
      <FeaturesDescription>
        {/* Connect BragAI with your favorite tools and platforms */}
        Build faster with modern development tools and features
      </FeaturesDescription>
      <IntegrationsGrid>
        {ThirdPartyIntegrations.map((integration, index) => (
          <IntegrationCard key={index}>
            <LogoContainer>
              <IntegrationLogo src={integration.logo} alt={`${integration.title} logo`} />
            </LogoContainer>
            <IntegrationContent>
              <IntegrationTitle>{integration.title}</IntegrationTitle>
              <IntegrationDescription>{integration.description}</IntegrationDescription>
            </IntegrationContent>
          </IntegrationCard>
        ))}
      </IntegrationsGrid>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 134px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FeaturesTitle = styled.h2`
  color: var(--brag-ai-porcelain);
  font: 600 36px/42px Inter;
  text-align: center;
  margin-bottom: 16px;
`;

const FeaturesDescription = styled.p`
  color: var(--brag-ai-silver-chalice);
  text-align: center;
  margin-top: 16px;
  font: 400 18px/28px Inter;
  max-width: 600px;
`;

const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 48px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const LogoContainer = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntegrationLogo = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`;

const IntegrationContent = styled.div`
  margin-left: 24px;
`;

const IntegrationTitle = styled.h3`
  color: var(--brag-ai-porcelain);
  font: 500 18px/24px Inter;
  margin: 0;
`;

const IntegrationDescription = styled.p`
  color: var(--brag-ai-silver-chalice);
  font: 400 14px/20px Inter;
  margin: 8px 0 0;
`;

const CardBase = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const IntegrationCard = styled(CardBase)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(62, 207, 142, 0.1);
    border-color: rgba(62, 207, 142, 0.2);

    ${IntegrationTitle} {
      color: var(--brag-ai-mint);
    }
  }
`;

export default IntegrationSection;
