import React, { useState } from "react";
import styled from "styled-components";
import Logo from "./Logo";

const HeaderMobile: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <MobileHeaderWrapper>
            <TopBar>
                <Logo />
                <MenuIcon onClick={toggleMenu}>&#9776;</MenuIcon>
            </TopBar>
            {isMenuOpen && (
                <FullScreenMenu>
                    <CloseIcon onClick={toggleMenu}>&times;</CloseIcon>
                    <MenuItems>
                        <MenuItem>Chat</MenuItem>
                        <MenuItem>Explore Brag</MenuItem>
                        <MenuItem>Blog</MenuItem>
                    </MenuItems>
                    <ButtonGroup>
                        <LoginButton>Login</LoginButton>
                        <SignUpButton>Sign Up</SignUpButton>
                    </ButtonGroup>
                </FullScreenMenu>
            )}
        </MobileHeaderWrapper>
    );
};

const MobileHeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 1000;
  font-family: var(--font-family-Font-1, Inter);
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const MenuIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: #fff;
`;

const FullScreenMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  color: #fff;
`;

const MenuItems = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 50px;
`;

const MenuItem = styled.a`
  font-size: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const SignUpButton = styled.button`
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const LoginButton = styled.button`
  background-color: transparent;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

export default HeaderMobile;
