import { supabase } from "./client";

export const signUpUser = async (email: string): Promise<{ error?: string }> => {
    try {
        const { error } = await supabase.auth.signUp({
            email,
            password: "Waitlist-Password123",
            options: {
                emailRedirectTo: "https://bragai.dev", // Optional redirect
            }
        });

        if (error) {
            return { error: error.message };
        }

        return {};
    } catch (e: any) {
        return { error: e.message || "An unexpected error occurred" };
    }
};
