import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-DB1LP6JLQE');

const theme = {
  // Add any theme properties here if needed
};

const App = () => {
  // Google Analytics tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Update the root render to use the App component
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
